import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {CustomerService} from '../service/customer.service';
import jwt_decode from 'jwt-decode';

import { Constant } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private logoutFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLogged() {
    return this.loggedIn.asObservable();
  }

  get isLogout(){
    return this.logoutFlag.asObservable();
  }
 
  constructor(
    private router: Router,
    private http: HttpClient,
    private customerService : CustomerService,
    private constant: Constant
  ) { }
 
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
 
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
 
 
  // Verify user credentials on server to get token
  auth(data): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/login', data , this.httpOptions);
  }
 
  // After login save token and other values(if any) in localStorage
  setCustomer(resp) {
    localStorage.setItem('username', resp.username);
    localStorage.setItem('access_token', resp.access_token);
    localStorage.setItem('company_id',resp.company_id);
    localStorage.setItem('company_name',resp.company_name);
    localStorage.setItem('employee_id',resp.employee_id);
    localStorage.setItem('employee_full_name',resp.employee_full_name);
    
    //this.userService.setUserLoginDetail();
    let token = resp.access_token;
    var payload = jwt_decode(token);
    console.log(payload);
    window.location.href = "/";
  }
 
  // After clearing localStorage redirect to login screen
  logout() {
    this.sendLogout().subscribe(response => {
      //console.log('logout response : ',response);
      if (response.code === '200') {
        //this.goLogout();
        this.logoutFlag.next(true);
        setTimeout(() => {
          this.logoutFlag.next(false);

          this.loggedIn.next(false);
          localStorage.clear();
          this.router.navigate(['/dashboard']);
        }, 1000);
      }
    }, error => {
      console.error(error);
    });

  }

  forceLogout(){
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/";
    }, 1000);
  }

  sendLogout(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
    return this.http.post(this.constant.API_ENDPOINT + '/logout', {} , this.httpOptions) ;
  }

  clearStorage(){
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/";
    }, 1000);
  }
  
  getAuthen(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;
    
    return this.http.get<any>(this.constant.API_ENDPOINT + '/authen', this.httpOptions);
  }

  getDevice(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    this.httpOptions.headers = headers;

    return this.http.get<any>(this.constant.API_ENDPOINT + '/authen/device', this.httpOptions);
  }

  // Get data from server for Dashboard
  getData(data): Observable<any> {
    return this.http.post<any>(this.constant.API_ENDPOINT + '/user', data, this.httpOptions);
  }
 
}