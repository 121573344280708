import { Component } from '@angular/core';
import { PermissionService } from 'src/app/shared/service';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'layout-menu-left',
  templateUrl: './menu-left.component.html'
})


export class MenuLeftComponent { 
  isAdmin = true;
  public isCustomerCollapsed = true;
  public isBranchCollapsed = true;
  public isSettingCollapsed = true;
  public isActive = true;
  public isSuperAdmin = false;
  companyName
  employeeFullName
   

  accessToken = null;
  constructor( 
    private permissionService :PermissionService
  ) { 
  }

  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    this.companyName = localStorage.getItem('company_name');
    this.employeeFullName = localStorage.getItem('employee_full_name');
  }

}
