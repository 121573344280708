import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DepartmentComponent } from './department.component';
import {
  DepartmentService
} from '../../shared';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from "ngx-spinner";
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxSpinnerModule,
    FormsModule
  ],
  declarations: [DepartmentComponent],
  exports: [
    DepartmentComponent
  ],
  providers: [
    DepartmentService,NgxSpinnerService
  ]
})
export class DepartmentModule { }
