import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CompanyComponent } from './company.component';
import {
  CompanyService
} from '../../shared';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from "ngx-spinner";
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxSpinnerModule,
    FormsModule
  ],
  declarations: [CompanyComponent],
  exports: [
    CompanyComponent
  ],
  providers: [
    CompanyService,NgxSpinnerService
  ]
})
export class CompanyModule { }
