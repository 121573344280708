import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { CompanyService } from '../service/company.service';
import {ActivatedRouteSnapshot,RouterStateSnapshot} from '@angular/router';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    private router: Router,
    private authService: AuthService,
    private companyService: CompanyService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var companyId = localStorage.getItem("company_id");
    this.companyService.getCompanyById(companyId).subscribe(response => {
      console.log('auth guards');
      // console.log(response);
    }, error => {
      console.error(error);
      console.error(error.status);
      if(error.status == 401){
        this.authService.forceLogout();
      }
      return false;
    });
    return true;
  }
 
}