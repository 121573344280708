import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent {

  accessToken = null;
  companyName
  employeeId
  employeeFullName

  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    this.companyName = localStorage.getItem('company_name');
    this.employeeId = localStorage.getItem('employee_id');
    this.employeeFullName = localStorage.getItem('employee_full_name');
    
  }

  unAuthen(){
    localStorage.removeItem("access_token");
    window.location.href = "/";
  }
}
