import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginInitComponent } from './login.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from "ngx-spinner";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxSpinnerModule,
    FormsModule
  ],
  declarations: [LoginInitComponent],
  exports: [
    LoginInitComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class LoginModule { }
