import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CalendarHolidayComponent } from './calendar.component';
import { OrgChartModule } from 'ng2-org-chart';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerService } from "ngx-spinner";
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    OrgChartModule,
    NgxSliderModule,
    NgxSpinnerModule,
    Daterangepicker
  ],
  declarations: [CalendarHolidayComponent],
  exports: [
    CalendarHolidayComponent
  ],
  providers: [
    NgxSpinnerService
  ]
})
export class CalendarHolidayModule { }
