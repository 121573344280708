import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/service';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'login-init',
  templateUrl: './login.component.html'
})
export class LoginInitComponent implements OnInit {

  loginForm: FormGroup;
  submitted_login = false;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private authenService: AuthService
  ) {
    this.loginForm = fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    });
   }

  ngOnInit() {
  } 

  // authen(e){
  //   e.preventDefault();
  //     var username = e.target.elements[0].value;
  //     var password = e.target.elements[1].value;
  //     var outsider = e.target.elements[2].checked;
  //     var employee = {'username':username, 'password':password, 'outsider': outsider};
  //     console.log(employee);
  //     this.employeeService.login(employee).subscribe(res => {
  //       if(res != null){
  //         console.log(res);
  //         localStorage.setItem('tisToken', JSON.stringify(res.data));
  //         var object = {value: "value", timestamp: new Date().getTime()}
  //         localStorage.setItem("key", JSON.stringify(object));
  //         window.location.href = "/";
  //       }else{
  //         localStorage.removeItem('tisToken');
  //         window.location.href = "/";
  //       }
  //     }, err => {
  //       console.error(err);
  //       this.failDialog("");
  //     });
  // }

  login(value){ 
    this.spinner.show();
    console.log(value);
    this.submitted_login = true;
    if(this.loginForm.invalid){
      return;
    }
    let param = {'username':value['username'],'password':value['password']};
    this.authenService.auth(param).subscribe(res=>{
        console.log(res.data);
        this.authenService.setCustomer(res.data);
        setTimeout(() => {this.spinner.hide();}, 500);
    }, error => {
      this.spinner.hide();
      console.log(error);
      //let msg = error.split(" ")[2];
      //let code = msg.split(/\n/)[0];
      this.failDialog("Invalid Username and Password");
    });
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'รหัสพนักงาน หรือ รหัสผ่านไม่ถูกต้อง',
      text: msg
    })
  }
}
