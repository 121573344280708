import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyComponent } from './setting/company/company.component';
import { DepartmentComponent } from './setting/department/department.component';
import { PositionComponent } from './setting/position/position.component';
import { MasterDataComponent } from './setting/masterData/masterData.component';
import { ProductCategoryComponent } from './stock/productCategory/productCategory.component';
import { StockComponent } from './stock/stock.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeAddComponent } from './employee/add/employee_add.component';
import { EmployeeViewComponent } from './employee/view/employee_view.component';
import { EmployeeEditComponent } from './employee/edit/employee_edit.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerAddComponent } from './customer/add/customer_add.component';
import { CustomerViewComponent } from './customer/view/customer_view.component';
import { CustomerEditComponent } from './customer/edit/customer_edit.component';
import { PasttimeCheckpointComponent } from './guard/pasttime/checkpoint/pasttimeCheckpoint.component';
import { ReportPasttimeCheckpointComponent } from './report/guard/pasttime/checkpoint/reportPasttimeCheckpoint.component';
import { PasttimePaymentComponent } from './guard/pasttime/payment/pasttimePayment.component';
import { WorkScheduleComponent } from './guard/permanent/workSchedule/workSchedule.component';
import { ReportSlipComponent } from './report/slip/reportSlip.component';
import { CheckTransferPayComponent } from './checktransferpay/checktransferpay.component';
import { CalendarHolidayComponent } from './setting/calendar/calendar.component';
import { OutsiderComponent } from './outsider/outsider.component';
import { ShareComponent } from './share/share.component';
import { GuardCheckPointComponent } from './guard/permanent/guardCheckPoint/guardCheckPoint.component';
import { ComparisonComponent } from './report/comparison/comparison.component';
import { ComparisonRecheckComponent } from './report/comparisonRecheck/comparisonRecheck.component';
import { ComparisonAuditComponent } from './report/comparisonAudit/comparisonAudit.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { InspectionComponent } from './setting/inspection/inspection.component';
import { CustomerAuditComponent } from './setting/customerAudit/customerAudit.component';
import { CustomerAuditManageComponent } from './setting/customerAudit/customerAuditMange/customerAuditManage.component';
import { RentHouseComponent } from './setting/rentHouse/rentHouse.component';
import { InsuranceComponent } from './setting/insurance/insurance.component';
import { PickStockComponent } from './stock/pickStock/pickStock.component';
import { RentHouseManageComponent } from './setting/rentHouse/rentHouseManange/rentHouseManage.component';
import { DocumentComponent } from './document/document.component';
import { ContractAuditComponent } from './setting/contractAudit/contract-audit.component';
import { GuardNotWorkComponent } from './report/guardNotWork/guardNotWork.component';
import { AdvMoneyComponent } from './setting/advMoney/advMoney.component';
import { AdvMoneyManageComponent } from './setting/advMoneyManage/advMoneyManage.component';
import { AdvMoneyAllComponent } from './setting/advMoneyAll/advMoneyAll.component';
import { CustomerSeqComponent } from './setting/customerSeq/customerSeq.component';
import { AdvTransferPayComponent } from './report/advTransferPay/advTransferPay.component';
import { ComparisonAdvComponent } from './report/comparisonAdv/comparisonAdv.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogoSignatureComponent } from './setting/logoSignature/logoSignature.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'setting/company',
    component: CompanyComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/department',
    component: DepartmentComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/position',
    component: PositionComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/master-data',
    component: MasterDataComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/calendar/holiday',
    component: CalendarHolidayComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/inspection',
    component: InspectionComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/customer-audit',
    component: CustomerAuditComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/customer-audit/manage/:id',
    component: CustomerAuditManageComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/rent-house',
    component: RentHouseComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/rent-house/manage/:id',
    component: RentHouseManageComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/insurance',
    component: InsuranceComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/advmoney',
    component: AdvMoneyComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/advmoney/manage/:empId',
    component: AdvMoneyManageComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/advmoneyAll',
    component: AdvMoneyAllComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/customer-seq',
    component: CustomerSeqComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/logo-signature',
    component: LogoSignatureComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'stock/category',
    component: ProductCategoryComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'stock',
    component: StockComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'stock/pick-stock',
    component: PickStockComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'employee',
    component: EmployeeComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'employee/add',
    component: EmployeeAddComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'employee/view/:id',
    component: EmployeeViewComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'employee/edit/:id',
    component: EmployeeEditComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    component: CustomerComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'customer/add',
    component: CustomerAddComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'customer/:id/:display',
    component: CustomerAddComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'guard/pasttime/checkpoint',
    component: PasttimeCheckpointComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/guard/pasttime/checkpoint',
    component: ReportPasttimeCheckpointComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'guard/pasttime/payment',
    component: PasttimePaymentComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'guard/permanent/shcedule',
    component: WorkScheduleComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'guard/notwork',
    component: GuardNotWorkComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'guard/permanent/checkPoint',
    component: GuardCheckPointComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/slip',
    component: ReportSlipComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/comparison',
    component: ComparisonComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/comparisonAudit',
    component: ComparisonAuditComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/comparisonAdv',
    component: ComparisonAdvComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/comparisonRecheck',
    component: ComparisonRecheckComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'report/adv-transfer-pay',
    component: AdvTransferPayComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'check-transfer-pay',
    component: CheckTransferPayComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'outsider',
    component: OutsiderComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'share',
    component: ShareComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'complaint',
    component: ComplaintComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'document',
    component: DocumentComponent
    ,canActivate: [AuthGuard]
  },
  {
    path: 'setting/contract-audit',
    component: ContractAuditComponent
    ,canActivate: [AuthGuard]
  }
  
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
