import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { CompanyService, UploadFilesService } from 'src/app/shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterDataService } from 'src/app/shared/service/masterData.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { LogoSignService } from 'src/app/shared/service/logoSign.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-logoSignature',
  templateUrl: './logoSignature.component.html',
  styleUrls: ['./logoSignature.component.css']
})
export class LogoSignatureComponent implements OnInit {  

  defaultImage = 'https://comvisitor-uat-bucket.s3.ap-southeast-1.amazonaws.com/tis_v2/images/default_img.jpg';
  //Main Logo
  fileUploadMainLogoObj: any;
  imageMainLogoUrl:any = this.defaultImage;
  //Hr
  fileUploadSignHrObj: any;
  imageSignHrUrl:any = this.defaultImage;
  //Logo
  fileUploadLogoObj: any;
  imageLogoUrl:any = this.defaultImage;
  //Finance
  fileUploadSignFnObj: any;
  imageSignFnUrl: any = this.defaultImage;
  
  progressInfos: any;
  displayMsgCheck = false;
  fileList = [];

  positionSlipName = 'การเงิน'
  slipName = ''

  constructor(
    private masterDataService: MasterDataService,
    private fb: FormBuilder,
    private uploadService: UploadFilesService,
    private spinner: NgxSpinnerService,
    private logoSignService: LogoSignService
    ) 
    {  }

  ngOnInit() { 
    this.getLogoSign();
  } 

  saveSlipName(){
    this.spinner.show();
    var req = {
      name: this.slipName,
      path: 'logoSign',
      type: 'SLIP_NAME',
      url: ''
    }
    this.logoSignService.createOrUpdate(req).subscribe(data => {
      this.spinner.hide();
      this.successDialog();
    }, error => {
      this.spinner.hide();
      console.error(error);
      var error_message = ""
      this.failDialog(error_message);
    });
  }

  savePositionSlipName(){
    this.spinner.show();
    var req = {
      name: this.positionSlipName,
      path: 'logoSign',
      type: 'POSITION_SLIP_NAME',
      url: ''
    }
    this.logoSignService.createOrUpdate(req).subscribe(data => {
      this.spinner.hide();
      this.successDialog();
    }, error => {
      this.spinner.hide();
      console.error(error);
      var error_message = ""
      this.failDialog(error_message);
    });
  }

  getLogoSign(){
    this.spinner.show();
    this.logoSignService.getLogoSign().subscribe(res=>{
      this.spinner.hide();
      console.log(res);
      this.fileList = res;
      //main
      let files = this.fileList.filter(item => item.type == 'M');
      if(files.length>0){this.imageMainLogoUrl = files[0].url}
      //logo
      files = this.fileList.filter(item => item.type == 'L');
      if(files.length>0){this.imageLogoUrl = files[0].url}
      //hr sign
      files = this.fileList.filter(item => item.type == 'HR');
      if(files.length>0){this.imageSignHrUrl = files[0].url}
      //finance sign
      files = this.fileList.filter(item => item.type == 'FN');
      if(files.length>0){this.imageSignFnUrl = files[0].url}
      //position slip
      files = this.fileList.filter(item => item.type == 'POSITION_SLIP_NAME');
      if(files.length>0){this.positionSlipName = files[0].name}
      //position slip
      files = this.fileList.filter(item => item.type == 'SLIP_NAME');
      if(files.length>0){this.slipName = files[0].name}
    }, error => {
      this.spinner.hide();
      console.error(error);
      var error_message = ""
      this.failDialog(error_message);
    });
  }

  upload(){
    console.log('upload')
    this.displayMsgCheck = false;
    if(this.fileUploadMainLogoObj){
      this.uploadProcess(this.fileUploadMainLogoObj.file,this.fileUploadMainLogoObj.oldFileName,'M');
    } 
    if(this.fileUploadLogoObj){
      this.uploadProcess(this.fileUploadLogoObj.file,this.fileUploadLogoObj.oldFileName,'L');
    } 
    if(this.fileUploadSignHrObj){
      this.uploadProcess(this.fileUploadSignHrObj.file,this.fileUploadSignHrObj.oldFileName,'HR');
    } 
    if(this.fileUploadSignFnObj){
      this.uploadProcess(this.fileUploadSignFnObj.file,this.fileUploadSignFnObj.oldFileName,'FN');
    }
  }

  uploadProcess(file, name, subType) {
    console.log(file);
    this.progressInfos = { value: 0, fileName: file.name };
    var formData: FormData = new FormData();
    console.log(file);
    formData.append('file', file);
    formData.append('type', "logoSign");
    formData.append('subType', subType);
    formData.append('name', name);

    this.uploadService.upload(formData).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos.value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(event);
          if(event.body.code === "success" && event.body.data){
            if(!this.displayMsgCheck){
              this.successDialog();
            } 
            this.displayMsgCheck = true;
          }
        }
      },
      err => {
        this.progressInfos.value = 0;
        //this.message = 'Could not upload the file:' + file.name;
        console.log(err); 
      });
  }

  onFileChanged(event,type) {
    this.preview(event.target.files,type);
    //this.photoList[position]['file'] = this.dataURLtoFile(this.photoList[position]['pathUrl'],position+'.jpg');
  }

  preview(files,type) {
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //this.message1 = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    if(type == '0'){ //sign hr
      reader.onload = (_event) => { 
        console.log(files[0])
        this.fileUploadSignHrObj = {
          oldFileName: files[0].name,
          pathUrl: reader.result,
          file: this.dataURLtoFile(reader.result,'sign_hr.jpg')
        }
        this.imageSignHrUrl = reader.result;
        console.log(this.fileUploadSignHrObj);
      }
    }else if(type == '1'){ //logo
      reader.onload = (_event) => { 
        console.log(files[0])
        this.fileUploadLogoObj = {
          oldFileName: files[0].name,
          pathUrl: reader.result,
          file: this.dataURLtoFile(reader.result,'logo.jpg')
        }
        this.imageLogoUrl = reader.result;
        console.log(this.fileUploadLogoObj);
      }
    }else if(type == '2'){ //sign finance
      reader.onload = (_event) => { 
        console.log(files[0])
        this.fileUploadSignFnObj = {
          oldFileName: files[0].name,
          pathUrl: reader.result,
          file: this.dataURLtoFile(reader.result,'sign_fn.jpg')
        }
        this.imageSignFnUrl = reader.result;
        console.log(this.fileUploadSignFnObj);
      }
    }else if(type == '3'){ //main logo
      reader.onload = (_event) => { 
        console.log(files[0])
        this.fileUploadMainLogoObj = {
          oldFileName: files[0].name,
          pathUrl: reader.result,
          file: this.dataURLtoFile(reader.result,'main_logo.jpg')
        }
        this.imageMainLogoUrl = reader.result;
        console.log(this.fileUploadMainLogoObj);
      }
    }  
}

dataURLtoFile(dataurl, filename) {
  try {
    //console.log(dataurl);
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
  }
  catch(err) {
    console.log(err);
    // document.getElementById("demo").innerHTML = err.message;
  }
  return new File([u8arr], filename, {type:mime});
}
 
  successDialog(){
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
