import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tis-web';
  accessToken = null;

  ngOnInit() {
    this.accessToken = localStorage.getItem('access_token');
    if(!this.accessToken){
      localStorage.clear();
      return;
    }
  }
  
}
